<template>
  <base-section id="marketing">
    <v-container>
      <v-row>
        <v-col cols="3" class="text-center hidden-md-and-down">
          <template v-for="(card, i) in cards">
            <v-btn
              :ripple="false"
              id="no-background-hover"
              depressed
              light
              fab
              height="130"
              x-large
              elevation="0"
              :key="card.title"
              @click="showFeature(card.title)"
            >
              <base-avatar-card :key="card.title" align="center" v-bind="card">
              </base-avatar-card>
            </v-btn>

            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>

        <v-col cols="2" class="hidden-sm-and-down text-center">
          <v-responsive height="calc(100% - 16px)" class="mt-2">
            <v-divider vertical />
          </v-responsive>
        </v-col>
        <div class="text-center hidden-lg-and-up">
          <base-info-card
            class="px-2"
            title="Select FirstVault Plan"
            pace="6"
          ></base-info-card>
          <v-col sm="6">
            <template v-for="card in cards">
              <v-btn
                :ripple="false"
                id="no-background-hover"
                fab
                height="50"
                small
                elevation="0"
                :key="card.title"
                @click="showFeature(card.title)"
              >
                <v-icon :key="card.title" align="center">
                  {{ card.icon }}
                </v-icon>
              </v-btn>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <!-- <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />   -->
            </template>
          </v-col>
        </div>

        <v-col cols="12" md="7">
          <base-subheading
            v-if="clickedItem === '' || clickedItem === 'FirstVault Basic'"
            size="display-1"
            title="FirstVault Basic"
            space="0"
          />
          <base-subheading
            v-else-if="clickedItem === 'FirstVault Premium'"
            size="display-1"
            title="FirstVault Premium"
            space="0"
          />
          <base-subheading
            v-else-if="clickedItem === 'FirstVault Platinum'"
            size="display-1"
            title="FirstVault Platinum"
            space="0"
          />

          <base-title class="primary--text" title="" tag="div" />

          <base-body
            v-if="clickedItem === '' || clickedItem === 'FirstVault Basic'"
          >
            FirstVault Basic is a lifetime free digital vault which helps you
            store passwords and other personal information. It works on a 'Zero
            Trust, Zero Knowledge' security architecture which ensures that your
            data is always secure. With features such as MFA, password
            generator, etc. FirstVault helps make your digital life easy and
            secure!
          </base-body>
          <base-body v-else-if="clickedItem === 'FirstVault Premium'">
            FirstVault Premium provides all the features of FirstVault Basic and
            more! Store custom information such as driving license, aadhaar
            details, accounts, etc. Easy to use interface with a secure and
            encrypted digital vault which can be accessed on multiple devices.
          </base-body>
          <base-body v-else-if="clickedItem === 'FirstVault Platinum'">
            FirstVault Platinum provides all the features of FirstVault Premium
            and more! create Family accounts, access credential history, etc.
            and share the same with the people you trust. Easy to use interface
            with a secure and encrypted digital vault which can be accessed on
            multiple devices.
          </base-body>

          <base-btn
            v-if="clickedItem === '' || clickedItem === 'FirstVault Basic'"
            to="/pricing"
            class="mb-12"
          >
            Sign Up Now
          </base-btn>
          <base-btn
            v-else-if="clickedItem === 'FirstVault Premium'"
            disabled
            class="mb-12"
          >
            Launching Soon
          </base-btn>
          <base-btn
            v-else-if="clickedItem === 'FirstVault Platinum'"
            disabled
            class="mb-12"
          >
            Launching Soon
          </base-btn>

          <v-row
            v-if="clickedItem === '' || clickedItem === 'FirstVault Basic'"
          >
            <v-col v-for="(text, i) in fvBasic" :key="i" cols="12" md="6">
              <base-list-item :text="text" />
            </v-col>
          </v-row>
          <v-row v-else-if="clickedItem === 'FirstVault Premium'">
            <v-col v-for="(text, i) in fvPremium" :key="i" cols="12" md="6">
              <base-list-item :text="text" />
            </v-col>
          </v-row>
          <v-row v-else-if="clickedItem === 'FirstVault Platinum'">
            <v-col v-for="(text, i) in fvPlatinum" :key="i" cols="12" md="6">
              <base-list-item :text="text" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionMarketing",
  methods: {
    showFeature(option) {
      this.clickedItem = option;
    },
  },

  data: () => ({
    clickedItem: "",
    cards: [
      {
        title: "FirstVault Basic",
        icon: "mdi-lock",
        outlined: true,
      },
      {
        title: "FirstVault Premium",
        outlined: true,
        icon: "mdi-security",
      },
      {
        title: "FirstVault Platinum",
        icon: "mdi-message-lock",
        outlined: true,
      },
    ],
    fvBasic: [
      "Free 1 Year Premium Trial",
      "Save upto 50 Passwords",
      "Multi-Factor Authentication",
      "Password Generator",
      "Zero-Trust, Zero-Knowledge Security",
      "Secure File Storage upto 25MB",
    ],
    fvPremium: [
      "All features of Basic",
      "Save Unlimited credential details",
      "Dark Web Monitoring",
      "Customized Secure Notes",
      "Category Based password storage",
      "Secure File Storage upto 1GB",
    ],
    fvPlatinum: [
      "All features of Premium",
      "Create Family Account",
      "Secure Password and File sharing",
      "Credential History Details",
      "24x7 emergency support",
      "Secure File Storage upto 10GB",
    ],
  }),
};
</script>
<style lang="scss">
#no-background-hover::before {
  background-color: transparent;
}
</style>